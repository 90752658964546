<template>
	<div>
		<div id="pageTitle">
			<b>최신 공지사항</b>
		</div>
		<div class="panel panel-flat mg-bt-0" style="border: none">
			<div class="panel-body panel-body-center mg-bt-0" style="padding: 0px 10px">
				<div class="row panel-detail" style="width: 100%; text-align: left">
					<div class="col-lg-12 inputDiv">
						<div class="row col-lg-12">
							<div class="col-lg-8" style="width: 100%">
								<!-- 공지사항 제목. 필수 -->
								<label class="col-lg-3 control-label ta-lf">{{ detailsFieldMap.noticeTitle }} :</label>
								<div class="ta-lf" style="margin-bottom: 10px">
									<div class="upl_span">
										{{ noticeInfo.noticeTitle }}
									</div>
								</div>
							</div>
						</div>
						<div class="row col-lg-12">
							<div class="col-lg-2 form-group" style="width: 100%">
								<!-- 첨부파일명 -->
								<label class="col-lg-3 control-label ta-lf">{{ detailsFieldMap.oriFileNm }} :</label>
								<div class="ta-lf" style="margin-bottom: 10px">
									<div class="upl_span">
										<a fileDownloadBtn @click="fileDownload">
											{{ noticeInfo.oriFileNm }}
										</a>
									</div>
								</div>
							</div>
						</div>
						<!-- 공지사항 내용. -->
						<div class="row col-lg-12 form-group" style="padding-right: 0px">
							<ckeditor v-model="noticeInfo.noticeCont" :config="editorConfig"></ckeditor>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
const mainUrl = apiIndex.main;
const noticeUrl = apiIndex.notice;

let axiosExtention;

export default {
	data: () => ({
		windowSize: [616, 668],
		noticeInfo: {},
		detailsFieldMap: {
			noticeTitle: '제목',
			noticeCont: '내용',
			oriFileNm: '첨부파일명',
			saveFileNm: '첨부파일명',
		},
		editorConfig: {
			toolbar: [
				{ name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'Underline'] },
				{ name: 'undo', items: ['Undo', 'Redo'] },
				{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
				{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			],
			height: '360px',
			readOnly: true,
			resize_enabled: false,
		},
		noticeId: '',
	}),
	mounted() {
		var url_string = window.location.href;
		var url = new URL(url_string);
		this.noticeId = url.searchParams.get('notice_id');
		let content = $('.content');
		if (content) {
			content.css('width', '100%');
			content.css('padding-top', '10px');
		}

		let that = this;

		// fixed popup window size 600*600
		$(window).resize(function () {
			window.resizeTo(that.windowSize[0], that.windowSize[1]);
		});

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.init();
	},
	methods: {
		init() {
			this.$axios
				.post(mainUrl.inqRecentlyNotice, { noticeId: this.noticeId })
				.then(r => {
					this.noticeInfo = r.data[0];
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		fileDownload() {
			// 첨부파일 다운로드
			this.$axios({
				url: noticeUrl.fileNotice + this.noticeInfo.saveFileNm,
				method: 'POST',
				responseType: 'blob', // important
			})
				.then(response => {
					let fileName = this.noticeInfo.oriFileNm;
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style>
.ta-lf {
	text-align: left;
	display: inline-block;
}
.mg-bt-0 {
	margin-bottom: 0px !important;
}
</style>
